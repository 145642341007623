	
	// Mentions légales

		.mentions-legales {
			padding: 5rem 0;

			@include respond-to("medium-plus") {
				padding: 3rem 0;
			}

			@include respond-to("tiny-plus") {
				padding: 2rem 0;
			}

			.container-medium {
				padding-bottom: 0;

				@include respond-to("large") {
					padding-top: 0;
				}
			}

			.container-small {
				padding-top: 0;
			}

			a {
				margin-left: .5rem;
				border-bottom: .2rem solid rgba($rouge-netime, .4);
				color: $rouge-netime;
			}
		}