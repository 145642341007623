
	// Accueil (accueil.html.twig)

		// Navigation & Général
			
			.accueil {

				.header {
					background-image: linear-gradient(to top, rgba($bleu-netime, 0), $bleu-netime);
				}

				.logo {
					.logo-titre {
						fill: $blanc;
					}
				}

				.nav-links-wrapper {
					@include respond-to("small") {
						background-color: $bleu-netime;
					}

					a {
						color: $blanc;
					}
				}

				section[class*='accueil-'] {
					padding: 5rem 0;
				}
			}

		// Entête

			.accueil {
				.entete {
					background: {
						color: $bleu-netime;
						image: url('../img/background-accueil.jpg');
					}

					@include respond-to("small") {
						.entete-sub {
							background-color: rgba($bleu-netime, .6);
						}
					}
				}

				.entete-texte {
					width: 65%;

					@include respond-to("tiny") {
						width: 100%;
					}

					h1 {
				    	margin-bottom: .75rem;
				    	font-size: 2.2rem;
				    	font-weight: 500;
    					letter-spacing: .1rem;
    					color: $rouge-netime;

    					span {
    						@include respond-to("large") {
								font-size: 1.8rem;
							}

							@include respond-to("small") {
								font-size: 1.5rem;
							}
    					}
				    }

				    p {
				    	margin: 0;
						color: $blanc;
						font: 3rem $font-stack-bold;
						line-height: 1.2;

						@include respond-to("large") {
							font-size: 2.2rem;
						}

						@include respond-to("small") {
							font-size: 1.8rem;
						}
				    }
				}
			}
		
		// Savoir-faire

			.accueil-savoir-faire {
				@include respond-to("medium-plus") {

					.container-large {
						& > .flex-container {
							flex-direction: column;
						}

						.accueil-agence-aside, .accueil-agence-sub {
							width: 100%;
						}
					}
				}

				@include respond-to("tiny-plus") {
					padding: 1rem 0 2rem;
				}
				
				// Gauche

					.accueil-agence-left {
						width: 30%;
						padding: 1rem 3rem 0 0;

						@include respond-to("medium-plus") {
							margin-bottom: 3rem;
							padding: 0 0 0 2rem;
						}

						p {
							font-size: 1.8rem;
							color: $bleu-netime;

							@include respond-to("medium-plus") {
								font-size: 1.6rem;
							}
						}

						a {
							margin-top: 4rem;

							@include respond-to("medium-plus") {
								margin-top: 2rem;
							}
						}
					}

				// Droite

					.accueil-agence-right {
						width: 70%;
					}

					.accueil-agence-sub {
						width: 50%;
						padding: 1.5rem 1rem;

						@include respond-to("tiny-plus") {
							width: 100%;
							padding: 1.5rem 0;
						}

						& > .flex-container {
							height: 100%;
						}

						svg {
							height: 5.5rem;
							width: 5.5rem;
							margin-right: 2rem;
							fill: $rouge-netime;

							@include respond-to("tiny-plus") {
								margin-right: 1rem;
							}

							@include respond-to("ultra-tiny") {
								width: 5.5rem;
								height: 5.5rem;
							}
						}

						h3 {
							font-size: 1.6rem;
							font-weight: 600;
							letter-spacing: .1rem;
							text-transform: uppercase;
							color: $color-base;
						}

						p {
							font-size: 1.4rem;
							color: rgba($bleu-netime , .75);
						}
					}
			}

		// Réalisations

			.accueil-realisations {
				background: $gris-bleu;

				@include respond-to("medium-plus") {
					padding: 3rem 0;
				}

				@include respond-to("tiny-plus") {
					padding: 2rem 0;

					& > .container-large {
						padding-top: 1rem;
					}
				}

				& > .container-large {
					padding-bottom: 0;
				}

				h2 {
					margin-bottom: 0;

					@include respond-to("medium-plus") {
						margin-left: 2rem;
					}
				}

				.accueil-realisations-texte {
					width: 80%;
					font-size: 2rem;
				}

				.accueil-realisation {
					.blocs-images-hover-content {
						background-color: $blanc;
					}
				}

				.accueil-link-projets {
					position: relative;
					flex-direction: justify-content;
					align-items: center;
					width: calc(33.33% - 4rem);
					margin: 2rem;
					padding: 4rem;
					border-radius: .3rem;
					font: 2.5rem $font-stack-bold;
					line-height: 2.5rem;
					color: $blanc;
					background-color: $rouge-netime;
					transition: all .2s ease-out;

					@include respond-to("large-plus") {
						width: calc(50% - 4rem);
					}

					@include respond-to("medium-plus") {
						font-size: 2rem;
					}

					@include respond-to("medium") {
						justify-content: center;
						width: 100%;
						padding: 5rem 2rem;
					}

					@include respond-to("tiny") {
						margin: 1rem;
					}

					@include respond-to("medium-up") {
						&:hover:before {
							opacity: 0;
						}

						&:before {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							content: '';
							border-radius: .3rem;
							background-image: linear-gradient(to bottom, $bleu-netime, transparent);
							transition: opacity .2s ease-out;
						}
					}

					span {
						position: relative;
					}
				}
			}

		// Témoignages

			.accueil-temoignages {
				padding: 5rem 0;

				@include respond-to("medium-plus") {
					padding: 3rem 0 5rem;
				}

				@include respond-to("tiny-plus") {
					padding: 2rem 0 4rem;
				}

				.temoignage {
					& > .flex-container {
						align-items: center;

						@include respond-to("tiny-plus") {
							flex-direction: column;
						}
					}
				}

				.temoignage-pic {
					width: 15rem;
					height: 15rem;
					margin-right: 3rem;
					border-radius: 50%;
				}

				.temoignage-texte p:last-of-type {
					margin-bottom: 2rem;
				}

				h3 {
					font-size: 2rem;
					line-height: 2rem;

					@include respond-to("tiny-plus") {
						text-align: center;
					}
				}

				p {
					font-size: 1.5rem;
					color: rgba($bleu-netime, .8);
				}
			}
