
	// Styles communs

		// Commun

			// Entête

				.entete {
					position: relative;
					background: {
						color: $gris-bleu;
						repeat: no-repeat;
						size: contain;
						position: right top;
					}

					@include respond-to("small") {
						background-size: cover;
					}

					&:after {
						content: '';
						display: block;
					    width: 100%;
						padding-top: 35%;

						@media (min-width: $big + 1) {
							padding-top: 30%;
						}

						@include respond-to("small") {
							padding-top: 25rem;
						}
					}

					.entete-sub {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
					}

					.container-medium {
						height: 100%;
					}

					.entete-texte {
						width: 50%;
						height: 100%;
						justify-content: flex-end;
						padding-bottom: 4rem;

						@include respond-to("medium-plus") {
							padding-bottom: 1rem;
						}

						@include respond-to("tiny") {
							width: 95%;
						}
					}

					h1 {
						font: 4rem $font-stack-bold;
						letter-spacing: -.1rem;
						color: $rouge-netime;
						line-height: .8;

						@include respond-to("medium") {
							font-size: 3rem;
						}

						span {
							width: 100%;
							display: inline-block;
							font: normal 2rem $font-stack-common;
							letter-spacing: .05rem;

							@include respond-to("medium") {
								font-size: 1.8rem;
							}
						}
					}
				}

			// Main

				.main {
					margin-top: -7.2rem;
				}

		// Trick flexbox footer collé en bas de page

			html {
				// Bug IE avec min-height sur enfant
				display: flex;
				flex-direction: column;
			}

			body {
				min-height: 100vh;
				display: flex;
				flex-direction: column;
				position: relative;
				font-family: $font-stack-common;

				&.is-mobile-nav-open {
					overflow: hidden;
				}
			}

			main {
				display: flex;
				flex: 1 1 auto;
				flex-direction: column;
			}

		// Typographie

			h1, h2, h3, h4 {
				margin: 0;
				color: $bleu-netime;
			}

			h2 {
				position: relative;
				margin-bottom: 2.75rem;
				font-family: $font-stack-bold;

				@include respond-to("medium-plus") {
					margin-bottom: 1.5rem;
					font-size: 2.5rem;
				}

				&:after {
					position: absolute;
					left: -1rem;
					bottom: -1rem;
					width: 4rem;
					height: .3rem;
					content: '';
					background: $rouge-netime;

					@include respond-to("medium-plus") {
						bottom: -.5rem;
					}
				}

				&.txtcenter:after {
					left: 50%;
					transform: translateX(-50%);
				}
			}

			p {
				color: rgba($bleu-netime, .75);	
			}

			a {
				text-decoration: none;
			}

			a, button {
				outline: none;
				cursor: pointer;
			}

			blockquote:before {
				color: rgba($rouge-netime, .4);
			}

			// Corrections

			input[type=text], textarea {
			  appearance: none;
			}

			textarea,
			input[type="text"],
			input[type="search"],
			input[type="button"],
			input[type="submit"] {
				-webkit-appearance: none;
				border-radius: 0;
			}

			// Correction frameborder (obsolète)

			iframe {
				border: none;
			}

			// Hack éléments scale tremblotants sur Safari

			.no-flickr-scale {
			    transform: translateZ(0);
			}

			// Hack éléments rotate tremblotants sur Chrome + Opéra

			.no-flickr-rotate {
			    backface-visibility: hidden;
			}

			// Surlignage custo

			::selection {
				color: $blanc;
			  	background: rgba($rouge-netime, .8);
			}
		
		// Container

			%container {
				position: relative;
			    margin: 0 auto;
			    padding: 2rem;

			    @include respond-to("tiny") {
			    	padding: 1.5rem;
			    }
			}

			.container-small {
				max-width: 80rem;
				@extend %container;
			}

			.container-medium {
				max-width: 110rem;
				@extend %container;
			}

			.container-large {
				max-width: 130rem;
				@extend %container;
			}

			.container-xlarge {
				max-width: 150rem;
				@extend %container;
			}

		// Logo

			.logo-svg {

				.logo-titre {
					fill: $bleu-netime;
				}

				.logo-baseline {
					fill: $rouge-netime;
				}
			}

		// Boutons

			.button {
				position: relative;
				display: inline-block;
				padding: .9rem 1.75rem;
				color: $blanc;
				font-size: 1.4rem;
				letter-spacing: .07rem;
				text-decoration: none;
				border: .2rem solid transparent;
				border-radius: .3rem;
				cursor: pointer;
				background: $rouge-netime;
				transition: all .2s ease-in-out;

				&.button-outline {
					color: $rouge-netime;
					border: .2rem solid $rouge-netime;
					background: transparent;
				}

				&.button-outline-white {
					color: $blanc;
					border: .2rem solid $blanc;
					background: transparent;
				}

				&.hover-cadre {
					&:before, &:after {
						position: absolute;
						width: 100%;
						height: 100%;
						border: .2rem solid $rouge-netime;
						content: '';
						transition: all .2s ease-out;
					}

					&:before {
						top: -.2rem;
						left: -.2rem;
					}

					&:after {
						right: -.2rem;
						bottom: -.2rem;
					}

					&:hover, &:focus {
						color: $rouge-netime;
						background-color: transparent;

						&:before {
							top: .1rem;
							left: .1rem;
						}

						&:after {
							right: .1rem;
							bottom: .1rem;
						}
					}
				}

				&.hover-cadre-white {
					&:before, &:after {
						border-color: $blanc;
					}

					&:hover {
						color: $blanc;
					}
				}
			}

		// Loader CSS

			.loader {
			    display: inline-block;
			    font-size: 1rem;
			    position: relative;
			    text-indent: -9999em;
			    border-top: .4rem solid rgba($blanc, .4);
			    border-right: .4rem solid rgba($blanc, .4);
			    border-bottom: .4rem solid rgba($blanc, .4);
			    border-left: .4rem solid rgba($blanc, 1);
			    transform: translateZ(0);
			    animation: load8 .5s infinite linear;
				
				&, &:after {
					width: 3rem;
				    height: 3rem;
				    float: left;
				    border-radius: 50%;
				}
			}

			@keyframes load8 {
			    0% {
			        transform: rotate(0);
			    }
			    100% {
			        transform: rotate(360deg);
			    }
			}

		// Style blocs images hover
			
			.blocs-images-hover {
				width: calc(33.33% - 4rem);
				margin: 2rem;
				position: relative;
				border-radius: .3rem;
				background: {
					repeat: no-repeat;
					size: cover;
					position: 50% 50%;
				}

				@include respond-to("large-plus") {
					width: calc(50% - 4rem);
				}

				@include respond-to("medium") {
					width: 100%;
				}

				@include respond-to("tiny") {
					margin: 1rem;
				}

				&:after {
					display: block;
				    content: "";
				    width: 100%;
				    padding-top: 85%;
				    border-radius: .3rem;
				    opacity: 0;
				    background-image: linear-gradient(to bottom, rgba($rouge-netime, .7), rgba($bleu-netime, .4));
				    transition: opacity .2s ease-out;

				    @include respond-to("large-plus") {
				    	padding-top: 60%;
				    }

				    @include respond-to("medium") {
						padding-top: 25rem;
					}
				}

				&:hover {
					&:after {
						opacity: 1;
					}

					.blocs-images-hover-content {
						background-color: $rouge-netime !important;
					}

					h3 {
						color: $blanc !important;
					}

					p {
						color: rgba($blanc, .75) !important;
					}
				}

				a {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 2;
				}

				.blocs-images-hover-content {
					position: absolute;
					width: 40rem;
					height: 10rem;
					right: -2rem;
					bottom: 2rem;
					padding: 1rem 1.5rem;
					z-index: 1;
					border-bottom: .2rem solid $rouge-netime;
					box-shadow: 0 0 1rem rgba($bleu-netime, .15);
					transition: all .2s ease-out;

					@include respond-to("tiny") {
						right: -1rem;
						width: calc(100% - 1rem);
						height: initial;
						padding: 1rem 1.5rem 1.5rem;
					}
									
					h3 {
						margin: 0 0 .4rem;
						font: 2.4rem $font-stack-bold;
						line-height: 1.4;
						color: $bleu-netime;
						transition: color .2s ease-out;

						@include respond-to("tiny") {
							font-size: 2rem;
						}
					}

					p {
						margin: 0;
						font-size: 1.4rem;
						color: rgba($bleu-netime, .75);
						transition: color .2s ease-out;
					}
				}
			}

		// Commun Mentions légales et Plan du site

			.mentionslegales, .plandusite {
				.entete:after {
					padding-top: 30rem;

					@include respond-to("large") {
						padding-top: 25rem;
					}

					@include respond-to("medium") {
						padding-top: 20rem;
					}

					@include respond-to("tiny") {
						padding-top: 17rem;
					}
				}

				.entete-texte {
					padding-bottom: 5rem;

					@include respond-to("large") {
						padding-bottom: 2rem;
					}

					@include respond-to("medium") {
						padding-bottom: .5rem;
					}
				}
			}

	// Trash

		// box-shadow: 0rem 0rem 3rem .01rem rgba($bleu-netime, .25);

				// &:after {
		//     position: absolute;
		//     bottom: -.6rem;
		//     left: -2rem;
		//     width: 8rem;
		//     height: .6rem;
		//     content: '';
		//     transform: translateY(-50%);
		//     background-color: $rouge-netime;
		// }