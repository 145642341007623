
	// Navigation

		// Général

			.header {
				position: relative;
				z-index: 2;
			}

		// Logo 

			.logo {
				position: relative;
				z-index: 1;

				svg {
					width: 13rem;
					height: 5rem;
				}
			}

		// Principale

			.navigation-main {
				padding: .75rem 2.5rem;
			}

			.is-mobile-nav-open .navigation-menu .nav-links-wrapper {
				pointer-events: auto;
				opacity: 1;
			}

			.navigation-menu {
				justify-content: flex-end;

				.nav-links-wrapper {
					margin-left: auto;

					@include respond-to("small") {
						flex-direction: column;
						justify-content: center;
						align-items: center;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						height: 100vh;
						pointer-events: none;
						opacity: 0;
						background-color: $gris-bleu;
						transition: opacity .2s ease-out;
					}

					a {
						position: relative;
						align-items: center;
						padding: 0 1.25rem;
						font-size: 1.4rem;
						font-weight: 600;
						letter-spacing: .1rem;
						color: $bleu-netime;
						text-transform: uppercase;
						transition: .2s cubic-bezier(0.645, 0.045, 0.355, 1) all;

						@include respond-to("small-up") {
							&.link-accueil {
								display: none;
							}

							&:hover:before {
								opacity: 1;
								transform: translateX(.5rem);
							}
						}

						@include respond-to("small") {
							padding: 1.5rem;
							font-size: 1.6rem;
						}

						&:before {
						    position: absolute;
						    bottom: 1rem;
						    left: 0;
						    display: block;
						    width: 4rem;
						    height: .2rem;
						    content: '';
						    opacity: 0;
						    background: $rouge-netime;
						    transform: translateX(-2rem);
						    transition: .2s cubic-bezier(0.645, 0.045, 0.355, 1) all;
						}

						&.is-active {
							color: $rouge-netime;

							&:before {
								opacity: 1;
								transform: translateX(.5rem);
							}
						}
					}
				}

				a:hover {
					color: $bleu-netime;
					transition: color .2s ease-out;
				}
			}

			.hamburger {

				.hamburger-inner,
				.hamburger-inner:before,
				.hamburger-inner:after {
					background-color: $rouge-netime;
				}
				
				@include respond-to("small-up") {
					display: none;
				}
			}