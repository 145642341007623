// Additionnal "utility" breakpoints aliases
// ex. @include respond-to("medium-up") {...}
@function breakpoint($bp) {
  @if $bp == 'ultra-tiny' {
    @return '(max-width: #{$ultra-tiny})';
  }
  @if $bp == 'tiny' {
    @return '(max-width: #{$tiny})';
  }
  @if $bp == 'tiny-plus' {
    @return '(max-width: #{$tiny-plus})';
  }
  @else if $bp == 'small' {
    @return '(max-width: #{$small})';
  }
  @else if $bp == 'medium' {
    @return '(max-width: #{$medium})';
  }
  @else if $bp == 'medium-plus' {
    @return '(max-width: #{$medium-plus})';
  }
  @else if $bp == 'large' {
    @return '(max-width: #{$large})';
  }
  @else if $bp == 'large-plus' {
    @return '(max-width: #{$large-plus})';
  }
  @else if $bp == 'extra-large' {
    @return '(max-width: #{$extra-large})';
  }
  @else if $bp == 'ultra-tiny-up' {
    @return '(min-width: #{$ultra-tiny + 1})';
  }
  @else if $bp == 'tiny-up' {
    @return '(min-width: #{$tiny + 1})';
  }
  @else if $bp == 'tiny-plus-up' {
    @return '(min-width: #{$tiny-plus + 1})';
  }
  @else if $bp == 'small-up' {
    @return '(min-width: #{$small + 1})';
  }
  @else if $bp == 'medium-up' {
    @return '(min-width: #{$medium + 1})';
  }
  @else if $bp == 'medium-plus-up' {
    @return '(min-width: #{$medium-plus + 1})';
  }
  @else if $bp == 'large-up' {
    @return '(min-width: #{$large + 1})';
  }
  @else if $bp == 'large-plus-up' {
    @return '(min-width: #{$large-plus + 1})';
  }
  @else if $bp == 'extra-large-up' {
    @return '(min-width: #{$extra-large + 1})';
  }
  @else if $bp == 'retina' {
    @return '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)';
  }
}

@mixin respond-to($value) {
  $string: breakpoint($value);
  @media screen and #{$string} {
    @content;
  }
}
