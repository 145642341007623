/* Autogrid object */
/* see http://codepen.io/raphaelgoetter/pen/KMgBJd */
@media (min-width: ($tiny + 1)) {
  [class^="autogrid"],
  [class*=" autogrid"] {
    display: flex;
  }

  [class^="autogrid"] > *,
  [class*=" autogrid"] > * {
    flex: 1;
    min-width: 0; /* avoid min-width:auto */
  }
}

/* Autogrid variants */
@media (min-width: ($tiny + 1)) {
  .has-gutter > *:not(:first-child) {
    margin-left: 1rem;
  }

  [class*="--reverse"] {
    flex-direction: row-reverse;
  }
}
