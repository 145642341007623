	
	// Erreur 404

		.error-404 {
			.logo-svg .logo-baseline {
				fill: $blanc;
			}

			.navigation-menu a:before {
				background-color: $blanc;
			}
			
			section {
				align-items: center;
				justify-content: center;
				min-height: calc(100vh - 22.6rem);
				background-color: $rouge-netime;

				.container-medium {
					width: 110rem;
					
					h1 {
						font-size: 7rem;
						color: $blanc;

						@include respond-to("medium-plus") {
							font-size: 5rem;
						}
					}

					a {
						color: $blanc;
						border-bottom: .2rem solid $rouge-netime;
						text-decoration: none;
						transition: border-color .2s ease-out;

						&:hover {
							border-color: rgba($blanc, .3);
						}
					}
				}
			}

			.footer-devis {
				display: none;
			}
		}