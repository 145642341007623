	
	// Savoir-faire

		// Entête

			.savoirfaire {
				.entete {
					background-image: url('../img/background-savoir-faire.jpg');
				}
			}

		// Présentation

			.savoirfaire-presentation {
				padding: 5rem 0 0;

				@include respond-to("medium-plus") {
					padding: 3rem 0 0;
				}

				@include respond-to("tiny-plus") {
					padding: 2rem 0 0;
				}

				p {
					font-size: 2rem;

					@include respond-to("medium") {
						font-size: 1.8rem;
					}
				}
			}

		// Étapes

			// Général

				.savoirfaire-step {
					padding: 5rem 0;
					overflow: hidden;

					&#strategie {
						padding: 1rem 0 5rem;

						@include respond-to("medium-plus") {
							padding: 0 0 3rem;

							& > .container-medium {
								padding-top: 0;
							}
						}

						@include respond-to("medium-plus") {
							padding: 0 0 2rem;
						}
					}

					&:not(#strategie) {
						@include respond-to("medium-plus") {
							padding: 3rem 0;
						}

						@include respond-to("medium") {
							padding: 2rem 0;

							.savoirfaire-step-left {
								padding-top: 0;
							}
						}

						@include respond-to("tiny-plus") {
							padding: 1rem 0;
						}
					}

					&:nth-child(even) {
						background: $gris-bleu;
					}

					> .container-medium {
						display: flex;

						@include respond-to("medium") {
							flex-direction: column;

							& > div {
								width: 100%;
								padding-left: 0;
							}
						}
					}
				}

				.savoirfaire-step-left {
					width: 25%;
					padding-top: 2rem;

					@include respond-to("large") {
						h2 {
							margin-left: 2rem;
						}
					}

					@include respond-to("medium") {
						display: flex;
						align-items: center;
						margin-bottom: 2rem;
					}

					@include respond-to("tiny") {
						flex-direction: column;

						h2 {
							margin: 0;
						}
					}

					svg {
						width: 10rem;
						height: 10rem;
						margin-top: 1rem;

						@include respond-to("medium") {
							order: -1;
							margin: 0 1rem 0 0;
						}

						@include respond-to("tiny") {
							margin: 0 0 1rem;
						}

						.fill-rouge {
							fill: $rouge-netime;
						}

						.fill-rose {
							fill: rgba($rouge-netime, .8);
						}

						.fill-blanc {
							fill: $blanc;
						}
					}
				}

				.savoirfaire-step-right {
					width: 75%;
					padding-left: 3rem;

					h3 {
						font-size: 1.6rem;
						font-weight: 600;
						color: $bleu-netime;
					}

					p {
						font-size: 1.4rem;
						color: rgba($bleu-netime, .75);
					}

					.savoirfaire-step-item {
						width: 50%;
						padding: 1.5rem;

						@include respond-to("tiny") {
							width: 100%;
							padding: 1rem 0;
						}
					}

					.img-wrapper {
						width: 100%;
						margin-top: 2rem;
						font-style: italic;

						@include respond-to("medium") {
							margin-top: 0;
						}

						@include respond-to("tiny") {
							margin-top: 2rem;
						}

						img {
							width: 100%;
						}
						
						p {
							text-align: right;
							color: rgba($bleu-netime, .6);
						}
					}
				}

		// Réalisations

			.savoirfaire-realisations {
				padding: 5rem 0;
				overflow: hidden;

				@include respond-to("medium-plus") {
					padding: 3rem 0;

					h2 {
						margin-bottom: 0;
					}
				}

				@include respond-to("medium") {
					padding: 2rem 0;
				}

				@include respond-to("tiny-plus") {
					h2 {
						margin-bottom: 1rem;
					}
				}
			}

			.savoirfaire-realisation {
				position: relative;
				width: calc(33.33% - 2rem);
				margin: 1rem;
				cursor: pointer;

				@include respond-to("large") {
					&:first-of-type, &:nth-of-type(2) {
						width: calc(50% - 2rem);
					}

					&:last-of-type {
						display: none;
					}
				}

				@include respond-to("tiny-plus") {
					width: 100% !important;
					margin: 0 0 1rem;
				}

				@include respond-to("medium-up") {
					&:hover .savoirfaire-realisation-texte:before {
						opacity: 1;
					}
				}

				a {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 1;
				}

				.savoirfaire-realisation-texte {
					align-items: center;
					justify-content: center;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					padding: 2rem;
					text-align: center;
					background-image: linear-gradient(to bottom, rgba($bleu-netime, .8), rgba($rouge-netime, .3));

					&:before {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						content: '';
						opacity: 0;
						background-image: linear-gradient(to bottom, rgba($rouge-netime, .8), rgba($bleu-netime, .3));
						transition: opacity .2s ease-out;

						@include respond-to("medium") {
							opacity: 1;
						}
					}
				}

				h4, p {
					position: relative;
				}

				h4 {
					color: $blanc;
				}

				p {
					color: rgba($blanc, .8);
				}
			}