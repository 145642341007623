	
	// Réalisations

		// Entête

			.realisations {
				.entete {
					background-image: url('../img/background-realisations.jpg');
				}

				// .container-xlarge {
				// 	width: 160rem;
				// 	max-width: 100%;
				// 	padding-top: 0;
				// }
			}

		// Présentation

			.realisations-presentation {
				padding: 5rem 0 3rem;

				@include respond-to("medium-plus") {
					padding: 3rem 0 1rem;
				}

				@include respond-to("tiny-plus") {
					padding: 2rem 0 0;
				}

				.container-medium {
					p {
						font-size: 2.5rem;

						@include respond-to("medium") {
							font-size: 1.8rem;
						}
					}
				}
			}

	// Réalisations (liste)

		.realisations-liste {
			padding-bottom: 5rem;

			@include respond-to("medium-plus") {
				padding-bottom: 3rem;
			}

			@include respond-to("tiny-plus") {
				padding-bottom: 2rem;
			}

			.blocs-images-hover-content {
				background-color: $gris-bleu;
			}
		}