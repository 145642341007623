		
	// Réalisation single

		// Entête

		.realisation-single {
			background-size: cover;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: '';
				background-image: linear-gradient(to bottom, rgba($gris-bleu, .95), rgba($gris-bleu, .7) 7%, rgba($gris-bleu, 0) 30%);
			}
		}

		// Bloc intro

		.realisation-single-intro {
			padding: 5rem 0;

			@include respond-to("medium-plus") {
				padding: 3rem 0;
			}

			@include respond-to("tiny-plus") {
				padding: 2rem 0;
			}

			h1 {
				margin-bottom: .5rem;

				@include respond-to("medium-plus") {
					font-size: 2.8rem;
				}

				@include respond-to("tiny-plus") {
					font-size: 2.4rem;
				}
			}

			h2 {
				margin-bottom: 2rem;
				font-size: 2.5rem;

				@include respond-to("medium-plus") {
					margin-bottom: 0;
					font-size: 2.2rem;
				}

				@include respond-to("tiny-plus") {
					font-size: 1.8rem;
				}
			}

			p {
				font-size: 1.7rem;

				@include respond-to("tiny-plus") {
					font-size: 1.6rem;
				}
			}

			.bloc-titre {
				padding-bottom: 0;

				@include respond-to("large-plus") {
					padding-top: 0;
				}

				@include respond-to("large") {
					padding-left: 3rem;
				}
			}

			.container-small {
				padding-top: 1rem;

				@include respond-to("tiny-plus") {
					padding-bottom: 0;
				}
			}

			// Détails réalisation

			.caracteristiques-wrapper {
				justify-content: center;

				@include respond-to("medium-plus") {
					padding-top: 0;
					padding-bottom: 0;
				}

				.caracteristique {
					width: 33.33%;
					padding: 2rem;
					text-align: center;

					@include respond-to("tiny") {
						width: 100%;
						padding: 1rem;
					}

					&.url {
						outline: none;

						p {
							display: inline;
							border-bottom: .2rem solid rgba($rouge-netime, .5);
							transition: border-color .2s ease-out;
						}

						&:hover {
							svg {
								fill: rgba($rouge-netime, .5);
							}

							p {
								border-color: $rouge-netime;
							}
						}
					}
				}

				svg {
					width: 100%;
					height: 8rem;
					fill: $rouge-netime;
					transition: fill .2s ease-out;
				}

				p {
					font-size: 1.4rem;
					font-weight: 600;

					span {
						border-bottom: .2rem solid rgba($bleu-netime, .2);
					}
				}
			}
		}

		// Contenu réalisation

		.realisation-single-content {
			padding: 5rem 0;
			
			@include respond-to("medium-plus") {
				padding: 3rem 0;
			}

			@include respond-to("small") {
				.flex-container:first-of-type {
					flex-direction: column;
					padding-top: 0;
					padding-bottom: 1rem;
				}
			}

			@include respond-to("tiny-plus") {
				padding: 2rem 0;
			}
			
			&:nth-of-type(even) {
				background-color: $gris-bleu;
			}

			.titre-contenu {
				width: 25%;
				margin: 0;

				@include respond-to("small") {
					width: 100%;
				}
			}

			.texte-contenu {
				width: 75%;
				padding-left: 2rem;
				font-size: 1.5rem;

				@include respond-to("small") {
					width: 100%;
					padding: 1rem 0 0;
				}
			}

			.desc-img {
				text-align: right;
				font-style: italic;
				font-size: 1.4rem;
				color: rgba($bleu-netime, .6);
			}

			.img-gif {
				width: 100%;
				height: auto;
				margin-top: 1.5rem;
			}

			// Boutons

			.boutons-realisation {
				text-align: right;

				@include respond-to("tiny") {
					text-align: center;

					a {
						display: block;
					}
				}
			}

			.share-fb {
				margin-right: 1.5rem;
				background-color: $bleu-netime;

				@include respond-to("tiny") {
					margin: 0 0 1.5rem;
				}
			}

			.retour-realisations {
				color: $bleu-netime;
				background-color: transparent;
			}

			.hover-cadre {
				&:before, &:after {
					border-color: $bleu-netime;
				}

				&:hover {
					color: $bleu-netime;
				}
			}
		}