/* ----------------------------- */
/* ==Misc (skip links, hyphens)  */
/* ----------------------------- */

/* styling skip links */
.#{$kna-namespace}skip-links {
  position: absolute;

  & a {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0.5em;
    background: black;
    color: white;
    text-decoration: none;

    &:focus {
      position: static;
      overflow: visible;
      clip: auto;
    }
  }
}

// hyphens on tiny screens
@media (max-width: $tiny) {
  /* you shall not pass */
  div,
  textarea,
  table,
  td,
  th,
  code,
  pre,
  samp {
    word-wrap: break-word;
    hyphens: auto;
  }
}

// use .no-wrapping to disallow hyphens on tiny screens
@media (max-width: $tiny) {
  .no-wrapping {
    word-wrap: normal;
    hyphens: manual;
  }
}

// SVG width IE fix
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%;
  }
}
