	
	// Pied de page

		// Devis

			.footer-devis {
				text-align: center;
				background: $rouge-netime;

				p {
					max-width: 50rem;
					margin: 0 auto;
					color: $blanc;
					font-size: 1.6rem;
					font-weight: 300;
				}

				a {
					margin-top: 2rem;
				}
			}

		// Footer

			.footer-credits {
				position: relative;
				overflow: hidden;
				color: $blanc;
				background-color: $bleu-netime;
				background-image: linear-gradient(to bottom, $bleu-netime 60%, rgba($noir, .3));

				// Partie supérieure

				.footer-credits-top {
					align-items: flex-end;
					padding: 5rem 1.5rem 2rem;

					@include respond-to("tiny") {
						padding-top: 3rem;
					}
				}

				.logo-wrapper {
					margin-right: 5rem;

					@include respond-to("tiny") {
						width: 100%;
						margin: 0 0 1.5rem;
					}
				}

				.logo-small {
					width: 8.5rem;
					height: 8.5rem;

					.logo-titre {
						fill: $blanc;
					}
				}

				p {
					display: block;
					margin-top: 0;
					font-weight: 300;
					color: $blanc;
				}

				.texte-wrapper {
					margin-right: 2rem;
				}

				.footer-titre {
					margin-bottom: 1.5rem;
					font-size: 1.7rem;
				}

				.footer-adresse, .footer-autres {
					margin: 0;
					padding: 1.5rem 0 .5rem;
					font-size: 1.4rem;
					color: rgba($blanc, .8);
				}

				.footer-adresse {
					position: relative;

					&:before {
						position: absolute;
						top: 0;
						left: 0;
						width: 2rem;
						height: .1rem;
						content: '';
						background: $blanc;

						@include respond-to("tiny") {
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}

				.footer-autres a {
					border-bottom: .2rem solid rgba($blanc, .4);
					color: inherit;
					transition: all .2s ease-out;

					&:hover {
						color: $blanc;
						border-color: $rouge-netime;
					}
				}

				// Partie inférieure

				.footer-credits-bottom {
					justify-content: space-between;
					font-size: 1.25rem;

					p, a {
						color: $rouge-netime;
					}

					a {
						display: inline-block;
						margin-right: 1rem;
						border-bottom: .2rem solid rgba($rouge-netime, .4);
						transition: all .2s ease-out;

						&:last-of-type {
							margin-right: 0;
						}

						&:hover {
							color: $blanc;
							border-color: $rouge-netime;
						}
					}
				}

				.btn-top {
					position: absolute;
					right: 0;
				}
			}