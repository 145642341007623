
	// Fonts

		//// SLICK

		@font-face {
			font-family: 'Slick';
			src: url('../font/Slick/slick.woff') format('woff'),
				url('../font/Slick/slick.eot') format('eot'),
				url('../font/Slick/slick.svg#Slick') format('svg'),
				url('../font/Slick/slick.ttf') format('truetype');
			font-weight: normal;
			font-style: normal;
		}
		
		// Palanquin Dark Semi-Bold

			@font-face {
				font-family: 'Palanquin Dark';
				src: url('../font/PalanquinDark-Regular/PalanquinDark-Regular.woff2') format('woff2'),
					url('../font/PalanquinDark-Regular/PalanquinDark-Regular.woff') format('woff'),
					url('../font/PalanquinDark-Regular/PalanquinDark-Regular.ttf') format('truetype'),
					url('../font/PalanquinDark-Regular/PalanquinDark-Regular.svg#PalanquinDark-Regular') format('svg');
				font-weight: normal;
				font-style: normal;
			}

		// Raleway Light

			@font-face {
				font-family: 'Raleway';
				src: url('../font/Raleway-Light/Raleway-Light.woff2') format('woff2'),
					url('../font/Raleway-Light/Raleway-Light.woff') format('woff'),
					url('../font/Raleway-Light/Raleway-Light.ttf') format('truetype'),
					url('../font/Raleway-Light/Raleway-Light.svg#Raleway-Light') format('svg');
				font-weight: 300;
				font-style: normal;
			}

		// Raleway Regular

			@font-face {
				font-family: 'Raleway';
				src: url('../font/Raleway-Regular/Raleway-Regular.woff2') format('woff2'),
					url('../font/Raleway-Regular/Raleway-Regular.woff') format('woff'),
					url('../font/Raleway-Regular/Raleway-Regular.ttf') format('truetype'),
					url('../font/Raleway-Regular/Raleway-Regular.svg#Raleway-Regular') format('svg');
				font-weight: normal;
				font-style: normal;
			}

		// Raleway Semi-Bold

			@font-face {
				font-family: 'Raleway';
				src: url('../font/Raleway-SemiBold/Raleway-SemiBold.woff2') format('woff2'),
					url('../font/Raleway-SemiBold/Raleway-SemiBold.woff') format('woff'),
					url('../font/Raleway-SemiBold/Raleway-SemiBold.ttf') format('truetype'),
					url('../font/Raleway-SemiBold/Raleway-SemiBold.svg#Raleway-SemiBold') format('svg');
				font-weight: 600;
				font-style: normal;
			}




