
	// Agence

		// Entête

			.agence {
				.entete {
					background-image: url('../img/background-agence.jpg');
				}
			}

		// Présentation

			.agence-presentation {
				padding: 5rem 0;

				@include respond-to("medium-plus") {
					padding: 3rem 0;
				}

				@include respond-to("tiny-plus") {
					padding: 2rem 0;
				}

				.container-medium p {
					font-size: 2.6rem;

					@include respond-to("medium") {
						font-size: 2.2rem;
					}
				}

				.container-small {
					@include respond-to("medium") {
						padding-top: 0;
					}

					p {
						font-size: 1.8rem;
						font-weight: 300;
						margin-bottom: 2.5rem;

						@include respond-to("medium") {
							margin-bottom: 1.5rem;
							font-size: 1.6rem;
						}

						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}

		// Équipe

			.agence-equipe {
				padding: 5rem 0;
				background: $gris-bleu;

				@include respond-to("medium-plus") {
					padding: 3rem 0;
				}

				@include respond-to("tiny-plus") {
					padding: 2rem 0;
				}

				.container-small {
					padding-bottom: 0;

					@include respond-to("medium") {
						h2 {
							margin-left: 1rem;
						}
					}
				}

				.container-large {
					justify-content: space-between;
					padding-top: 1rem;
				}

				.agence-equipe-membre {
					width: 25%;
					padding: 1rem;

					@include respond-to("medium") {
						width: 50%;
					}

					@include respond-to("tiny") {
						width: 100%;
					}

					img {
						width: 100%;
						margin-bottom: 1.6rem;

						@include respond-to("tiny") {
							margin-bottom: .5rem;
						}
					}

					span {
						&:first-of-type {
							font: 2.2rem $font-stack-bold;
							color: $bleu-netime;

							@include respond-to("tiny-plus") {
								font-size: 1.8rem;
							}
						}

						&:last-of-type {
							color: rgba($bleu-netime, .8);
						}
					}

					blockquote {
						color: $rouge-netime;
					}
				}
			}