
	// Plan du site

		.plan-du-site {
			padding: 3rem 0 5rem;

			@include respond-to("large") {
				.container-medium {
					padding-top: 0;
					padding-bottom: 0;
				}
			}

			@include respond-to("medium-plus") {
				padding: 2rem 0 3rem;
			}

			@include respond-to("tiny-plus") {
				padding: 1rem 0 2rem;
			}

			a, p {
				color: $bleu-netime;
			}

			.plan-item {
				margin-bottom: 1.5rem;

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			.item-sub-wrapper {
				position: relative;
				padding-top: 1.3rem;

				&:before {
					position: absolute;
					top: -.5rem;
					left: 2rem;
					width: 3rem;
	    			height: 3rem;
					content: '';
					border-left: .2rem solid rgba($bleu-netime, .2);
					border-bottom: .2rem solid rgba($bleu-netime, .2);
				}
			}

			.plan-item-titre, .item-sub {
				display: block;
				padding: .5rem 1rem;
			}

			.plan-item-titre {
				font: 2.4rem $font-stack-bold;
				line-height: 3rem;

				@include respond-to("large") {
					font-size: 2rem;
				}

				span {
					display: inline-block;
					border-bottom: .2rem solid rgba($bleu-netime, .2);
					color: $bleu-netime;
					transition: all .2s ease-out;
				}

				&:hover span {
					border-color: rgba($rouge-netime, .4);
					color: $rouge-netime;
				}
			}

			.item-sub {
				padding-left: 6rem;
				line-height: 1.8rem;
				color: $rouge-netime;
				transition: color .2s ease-out;

				@include respond-to("small") {
					font-size: 1.5rem;
				}

				&:hover {
					color: rgba($rouge-netime, .6);
				}
			}
	}