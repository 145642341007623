	
	// Contact

		// Entête

			.contact {
				.entete {
					background-image: url('../img/background-contact.jpg');
				}
			}

			// Présentation

			.contact-presentation {
				padding: 5rem 0 0;

				@include respond-to("medium-plus") {
					padding: 3rem 0 0;
				}

				@include respond-to("tiny-plus") {
					padding: 2rem 0 0;
				}

				.container-medium {
					@include respond-to("medium") {
						padding-bottom: 0;
					}

					p {
						font-size: 2rem;

						@include respond-to("medium") {
							font-size: 1.8rem;
						}
					}
				}
			}

			// Formulaire

			.contact-formulaire {
				padding: 0 0 5rem;

				@include respond-to("medium-plus") {
					padding: 0 0 3rem;
				}

				@include respond-to("tiny-plus") {
					padding: 0 0 2rem;
				}
				
				label {
					position: absolute;
					left: -.5rem;
					top: 0;
					margin: 0;
					padding: 1rem;
					opacity: .7;
					transform: scale(.9);
					transition: all .2s ease-out;

					@include respond-to("tiny") {
						font-size: 1.3rem;
					}
				}

				input, textarea {
					width: 100%;
					margin: 0 0 0 auto;
					padding: 1rem;
					outline: none;
					border: none;
					color: $bleu-netime;
					background-color: $gris-bleu;
					transition: all .2s ease-out;
				}

				// Trick pour éviter le zoom sur les Iphones

				input, input:focus, textarea, textarea:focus {
					font-size: 16px;
				}

				.form-requis {
					font-size: 1.4rem;
					margin: 1rem 0 5rem;

					@include respond-to("medium-plus") {
						margin-bottom: 3rem;
					}

					@include respond-to("tiny-plus") {
						margin-bottom: 2rem;
					}

					@include respond-to("tiny") {
						margin-bottom: 4rem;
					}

					.form-message {
						margin-top: 2rem;
						padding: 1.5rem;
						font-size: 1.7rem;
						
						p {
							color: $blanc;
						}

						&.form-notice {
							background-color: #61b978;
						}

						&.form-erreur {
							background-color: $rouge-netime;

							a {
								color: $blanc;
								border-bottom: .2rem solid rgba($blanc, .5);
							}
						}
					}
				}

				.form-item {
					display: flex;
					position: relative;
					width: 100%;
					margin: 0 0 2rem;
					transform: translateZ(0);

					@include respond-to("tiny") {
						margin-bottom: 3rem;
					}

					&:last-of-type {
						margin: 0;
					}

					&.form-active {
						label {
							left: 0;
							padding: 1rem 1.5rem 1rem 0;
							opacity: 1;
							transform: scale(1);

							@include respond-to("tiny") {
								top: -2.8rem;
							}
						}

						input, textarea {
							width: calc(100% - 15rem);
							background-color: rgba($bleu-moyen, .2);

							@include respond-to("tiny") {
								width: 100%;
							}
						}
					}
				}

				.captcha-wrapper {
					align-items: flex-start;
					justify-content: space-between;
					margin-top: 2rem;

					@include respond-to("tiny") {
						flex-direction: column;
						align-items: center;
						justify-content: flex-start;
					}

					.form-item {
						width: 11rem;

						@include respond-to("tiny") {
							order: -1;
							width: 100%;
							margin: 1rem 0 2rem;

							button {
								width: 100%;
							}
						}
					}
				}

				.hover-cadre {
					background-color: $bleu-netime;

					&:before, &:after {
						border-color: $bleu-netime;
					}

					&:hover {
						color: $bleu-netime;
					}
				}
			}